<h2 matDialogTitle>{{title}}</h2>
<mat-dialog-content>
 <span><mat-icon>warning</mat-icon> {{message}}</span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [matDialogClose]="true">
      <mat-icon>check</mat-icon>Yes</button>
  <span style="width:10px"></span>
  <button mat-raised-button [matDialogClose]="false">
      <mat-icon>close</mat-icon>
      No</button>
</mat-dialog-actions>