import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit {
  constructor(private authService: AuthService
  ) {
  }

  ngOnInit() {
  }

  public logout() {
    this.authService.logout();
  }

  public toggleNavbar() {
    const classes = document.querySelector('body').classList;
    const className = 'mini-navbar';
    if (classes.contains(className)) {
      classes.remove(className);
    } else {
      classes.add(className);
    }
  }

  public get userName() {    
    return this.authService.userInfo.profile.name;
  }
}
