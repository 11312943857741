import {EventEmitter, Injectable, Output} from '@angular/core';
import { HttpBackend, HttpClient } from "@angular/common/http";
import { AuthService } from '../shared/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private apiBase = 'api/';  
  private config: any;

  constructor(private handler: HttpBackend) {
  }

  initaliseConfig(): Promise<any> {
    const http = new HttpClient(this.handler);
    return http.get(this.apiBase + 'configuration/get').toPromise().then(r => {
      this.config = r;
    });
  }

  getAdalConfig(): any {
    return {
      tenant: this.config.tenantId,
      clientId: this.config.clientId,
      redirectUri: window.location.origin + '/',
      postLogoutRedirectUri: window.location.origin + '/',
      cacheLocation: 'localStorage'
      //callback: () => {
      //  this.authService.refreshDataFromCache();
      //},
    };

  }

  getAppInsightsConfig(): any {
    return {
      instrumentationKey: this.config.instrumentationKey
    };
  }

}




