<!--<mat-card class="flex flex-fill" style="align-self: center; padding-left: 25%; padding-right:30%;">-->
  <!--padding-left: 30px;padding-right: 50px;-->
  <!--<mat-card-header>-->
    <!--<mat-card-title>
      <h5>Automated traded portfolios</h5>
      <button style="float:right" mat-flat-button color="primary" (click)="stop()" [disabled]="!canEdit" title="Cancels all automated portfolios">
        STOP
      </button>
    </mat-card-title>-->
    <mat-slide-toggle [(ngModel)]="checked"
                      class="example-margin"
                      [color]="color"
                      (change)="changed()">
      FC_Adj
    </mat-slide-toggle>
  <!--</mat-card-header>-->
  


<!--</mat-card>-->
