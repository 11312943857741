import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()

export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let navigationExtras: NavigationExtras = {
      queryParams: {'redirectUrl': route.url}
    };

    if (!this.authService.isAuthenticated) {
      this.router.navigate([''], navigationExtras);
      return false;
    }

    return true;
  }

}
