export class EnumModel {
  public id: string;
  public name: string;
  public value: any;
  
  static GetEnumObjects(enumReference): EnumModel[] {
    const result = [];
    for (const k in enumReference) {
      if (k.toString() === parseInt(k, 10).toString()) {
        result.push({ id: k, value: enumReference[k] });
      }
    }
    return result;
  }

  static GetEnumObjectsFromEnumstr(enumReference, enumStrReference): EnumModel[] {
    const result = [];
    for (const k in enumReference) {
      if (k.toString() === parseInt(k, 10).toString()) {
        result.push({ name: enumReference[k], value: enumStrReference[enumReference[k]] })
        //result.push({ id: k, value: enumReference[k] });
      }      
    }
    return result;
  }
}

export enum Operations {
  None = 0,
  // tslint:disable-next-line:no-bitwise
  Read = 1 << 0,
  // tslint:disable-next-line:no-bitwise
  ReadWrite = 1 << 1  
}