import { Component, OnInit } from '@angular/core';
//import { AdalService } from '../../shared/adal.service';
//import { adal } from 'adal-angular';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {  
  //user: adal.User;

  constructor(//private adalService: AdalService,
    private router: Router, private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap(route => route.data))
        .subscribe((event) => this.titleService.setTitle('Likron - ' + event['title']));
  }

  ngOnInit() {
    //this.user = this.adalService.userInfo;    
  }
 
}
