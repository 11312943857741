import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '../loading-indicator.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  isLoading = false;

  constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  ngOnInit() {
    this.loadingIndicatorService.getLoading().subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

}
