import { Injectable } from '@angular/core';
import { Observer ,  Observable } from 'rxjs';



@Injectable()
export class LoadingIndicatorService {
  private _observer: Observer<boolean>;
  private _observable: Observable<boolean>;
  private numberOfRequests = 0;

  constructor() {
    this._observable = Observable.create(observer => this._observer = observer);
  }

  setLoading(isLoading: boolean) {
    if (isLoading) {
      this.numberOfRequests++;
    } else {
      this.numberOfRequests--;
    }
    if (this.numberOfRequests < 0) {
      this.numberOfRequests = 0;
    }
    if (this._observer) {
      this._observer.next(this.numberOfRequests !== 0);
    }
  }

  getLoading() {
    return this._observable;
  }
}
