/// <reference path="../../../node_modules/@types/adal/index.d.ts" />
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
//import 'expose-loader?AuthenticationContext!../../../node_modules/adal-angular/lib/adal.js';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AsyncSubject, BehaviorSubject, Observable} from "rxjs";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {Router} from '@angular/router';
import { throwError } from "rxjs/internal/observable/throwError";
import * as AuthenticationContext from "adal-angular";
import { ElementSchemaRegistry } from '@angular/compiler';


@Injectable()
export class AuthService {
  static createAuthContextFn: adal.AuthenticationContextStatic = AuthenticationContext;

  private context: adal.AuthenticationContext;
  private;
  constructor(private configService: ConfigService) {
    this.context = new AuthService.createAuthContextFn(configService.getAdalConfig());
  }

  public get userInfo() {
    return this.context.getCachedUser();
  }

  public get isAuthenticated(): boolean {
    return this.userInfo && (this.getToken() !== null || this.getToken() !== undefined);
  }

  login() {
    this.context.login();
  }

  logout() {
    this.context.logOut();
  }

  handleCallback() {
    this.context.handleWindowCallback();
    this.context.getCachedUser();
  }

  public accessToken(): Observable<string> {
    let tokenSubject = new AsyncSubject<string>();
    this.context.acquireToken(this.configService.getAdalConfig().clientId, (msg, token) => {
        if (msg || !token) {
        console.log('ADAL Error Occurred: ' + msg);
        tokenSubject.error(msg);
        }
        tokenSubject.next(token);
        tokenSubject.complete();
    });

    return tokenSubject.asObservable();
  }

  public getToken(): string {
    return this.context.getCachedToken(this.configService.getAdalConfig().clientId);
  }

}

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authService: AuthService, private router: Router) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}})
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    var token = this.authService.getToken();
    if(token) {
      return next.handle(this.addToken(req, this.authService.getToken()))
        .pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>error).status) {
                case 400:
                  return this.handle400Error(error);
                case 401:
                  return this.handle401Error(req, next);
                default:
                  return throwError(error);
              }
            } else {
              return throwError(error);
            }
          })
        );
    }
    else  {
      console.log("Refresh token");
      return this.refreshToken(req, next)
        .pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>error).status) {
                case 400:
                  return this.handle400Error(error);
                case 401:
                  return this.handle401Error(req, next);
                default:
                  return throwError(error);
              }
            } else {
              return throwError(error);
            }
          })
        );
    }

  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      console.log("Received a 400 stating the token is no longer valid. Logging user out. Error:" + JSON.stringify(error));
      return this.logoutUser();
    }

    return throwError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    return this.refreshToken(req, next);
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler)  {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      console.log("Received a 401 response attempting to refresh the token.");
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.accessToken()
        .pipe(
          switchMap((newToken: string) => {
            if (newToken) {
              console.log("Token refreshed successfully.");
              this.tokenSubject.next(newToken);
              this.isRefreshingToken = false;
              return next.handle(this.addToken(req, newToken));
            }

            // If we don't get a new token, we are in trouble so logout.
            this.isRefreshingToken = false;
            console.log("Unable to refresh token, logging user out, Reason: We didn't receive a refresh token from Azure");
            return this.logoutUser();

          }),
          catchError(error => {
            console.log("An error occurred while refreshing the token the user will be logged out. Error: " + JSON.stringify(error));
            this.isRefreshingToken = false;
            return this.logoutUser();
          }),
        )

    } else {
      console.log("Trying to get latest refresh token.");
      return this.tokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }

  logoutUser() {
    console.log('Logging user out current url: ' + this.router.url);

    this.authService.logout();
    //this.router.navigate(['login']);
    return throwError("token expired logging user out");
  }
}
