import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './dialog-warning.component.html',
  styleUrls: ['./dialog-warning.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class WarningDialog {

    constructor( @Inject(MAT_DIALOG_DATA) public errorMessage: string) { }

    onNavigateToRequest() {


    }
}
