<div class="wrapper">
  <app-toolbar></app-toolbar>

  <div class="content">    
    <ng-container>
      <router-outlet></router-outlet>
    </ng-container>
    <app-loading style="height: 0;"></app-loading>

  </div>
  <app-footer></app-footer>
</div>
