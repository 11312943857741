import { Injectable } from '@angular/core';
// import { AdalService, AuthHttp } from 'ng2-adal/dist/core';
// import { Http as NgHttp, any, any } from '@angular/http';
import { HttpClient, HttpClientModule, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http'
import { LoadingIndicatorService } from './loading-indicator.service';
import { MatDialog } from '@angular/material/dialog';
import { AppErrorDialog } from './dialog-error/dialog-error.component';
import { environment } from '../../environments/environment';
import { timeout, finalize, catchError, mergeMap } from 'rxjs/operators';
import {
  Observable,
  Subject,
  asapScheduler,
  pipe,
  of,
  from,
  interval,
  merge,
  fromEvent
} from "rxjs";

import { throwError } from 'rxjs';

@Injectable()
export class Http extends HttpClient {

  constructor(private httpHandler: HttpHandler, private loadingIndicatorService: LoadingIndicatorService, private dialog: MatDialog) {
    super(httpHandler);
  }

  get(url: string, options?: any): Observable<any> {
    options = options || {};
    return this.augmentRequest(super.get.bind(this), url, options) as Observable<any>;
  }

  post(url: string, body: any, options?: any, fireAndForget: boolean = false): Observable<any> {
    options = options || {};
    if (fireAndForget) {
      return super.post(url, body, options);
    }
    return this.augmentRequestWithBody(super.post.bind(this), url, body, options) as Observable<any>;
  }

  delete(url: string, options?: any): Observable<any> {
    options = options || {};
    return this.augmentRequest(super.delete.bind(this), url, options) as Observable<any>;
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    options = options || {};
    return this.augmentRequestWithBody(super.patch.bind(this), url, body, options) as Observable<any>;
  }

  put(url: string, body: any, options?: any): Observable<any> {
    options = options || {};
    return this.augmentRequestWithBody(super.put.bind(this), url, body, options) as Observable<any>;
  }

  head(url: string, options?: any): Observable<any> {
    options = options || {};
    return this.augmentRequest(super.head.bind(this), url, options) as Observable<any>;
  }

  augmentRequest(call: (url: string, options: any) => Observable<any>,
                 url: string,
                 options: any) {
    this.loadingIndicatorService.setLoading(true);
    return call(url, options)
      .pipe(
      timeout(environment.timeout * 60),
      catchError(this.handleErrors.bind(this)),   
      finalize(() => this.loadingIndicatorService.setLoading(false)));
  }

  augmentRequestWithBody(call: (url: string, body: any, options: any) => Observable<any>,
                         url: string,
                         body: any,
                         options: any) {
    this.loadingIndicatorService.setLoading(true);
    return call(url, body, options)
      .pipe(
      timeout(environment.timeout * 60),
      catchError(this.handleErrors.bind(this)),   
      finalize(() => this.loadingIndicatorService.setLoading(false)));
  }

  private handleErrors(error: any) {
    let errorMessage = `${error.statusText}: `;
    if (!error.statusText || error.statusText.length === 0) {
      switch (error.status) {
        case 0:
          errorMessage = 'Could not reach backend server. ';
          break;
        default:
          errorMessage = 'An unknown error occured. ';
          break;
      }
    }
    if (error.error) {
      const errorInfo = error.error;
      if (errorInfo && errorInfo.error) {
        errorMessage += errorInfo.error;
      } else if (errorInfo && errorInfo.message) {
        errorMessage += errorInfo.message;
      }
    }
    this.dialog.open(AppErrorDialog, {
      height: '400px',
      width: '600px',
      data: errorMessage,
    });
    return throwError(error);
  }
}