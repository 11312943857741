
import {map} from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmationDialog } from './dialog-confirm.component';

@Injectable()
export class DialogConfirmService {
  dialogRef: MatDialogRef<ConfirmationDialog>;
  constructor(public dialog: MatDialog, ) { }

  openDialog(title: string, message: string) {
    const config = {
      height: '170px',
      width: '500px',
      position: {
        top: '200px',
        left: '400px'
      },
      data: { message: message, title: title }
    };
    this.dialogRef = this.dialog.open(ConfirmationDialog, config);

    return this.dialogRef.afterClosed().pipe(
      map(response => {
        this.dialogRef = null;
        return response;
      }));
  }
}
