import { OnInit, Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: "customErrorComponent",
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('customErrorComponent | ngOnInit...');
  }
}
