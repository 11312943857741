import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AuthService } from './shared/auth.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private authService: AuthService) {    
  }

  ngOnInit(): void {
    this.authService.handleCallback();
    if (!this.authService.isAuthenticated) {
      this.authService.login();
    }
  }

  ngAfterViewInit(): void {
    const handler = function () {
      const $this = $(this);

      if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
        $this.attr('title', $this.text());
      }
    };
    $(document).on('mouseenter', 'mat-cell', handler);
    $(document).on('mouseenter', 'mat-header-cell', handler);
  }  
}
