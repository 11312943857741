
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Operations } from './enums';
import { Http } from './http.service';

@Injectable()
export class UserService {

    constructor(private http: Http) { }

    getOperations(): Observable<Operations> {
        const url = 'api/user/get';
        return this.http.get(url).pipe(
            map(response => response as Operations));
    }

    isAuthorized(operation: Operations): Observable<boolean> {
        return this.getOperations().pipe(
       map(result => (result & operation) === operation));
  }

  isAuthorizedReadOrWrite(readOperation: Operations, writeOperation: Operations): Observable<boolean> {
    return this.getOperations().pipe(
      map(result => ((result & readOperation) === readOperation) ||
      ((result & writeOperation) === writeOperation)));
  }
  getADUsers(): Observable<string[]> {
    const url = 'api/user/getADUsers';
    return this.http.get(url).pipe(
      map(response => response as string[]));
  }
}
