<mat-toolbar color="primary">
  <span>Likron Web Platform</span>
  <app-menu></app-menu>

  <!-- This fills the remaining space of the current row -->
  <span class="flex-fill"></span>
  <mat-label style="font-size: 15px">Hello: {{userName}}</mat-label>
  <span>&nbsp;&nbsp;</span>
  <button mat-mini-fab (click)="logout()" title="Logout">
    <mat-icon>lock_outline</mat-icon>
  </button>

</mat-toolbar>
