import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http } from '../shared/http.service';
import { environment } from '../../environments/environment';
import { PortfolioGroupDto, PortfolioConfigurationDto, PortfolioConfigurationAuditCopyDto } from '../portfolio-configuration/Portfolio-model';
import { StandingData } from './models/standing-data.model';

@Injectable()
export class DataService {

private apiBase = 'api/'; 
constructor(private http: Http) {
}

  public getPortfolio(): Observable<PortfolioGroupDto[]> {
    //const input = moment(tradeDate).format('YYYY-MM-DD');
    //const url = `${environment.backendUrl}/api/order/GetByTradedateAsync?tradeDate=${input}`;
    // const url = 'assets/testdata/orders.json';
    //return this.http.get(url)
    //  .map(response => {
    //    return response as OrderDto[];
      //  });
      return this.http.get(`${this.apiBase}portfolio/Get`).pipe(
      map(response => {
        return response as PortfolioGroupDto[];
      }));
  }

  public updatePortfolioConfiguration(portfolioConfigurationDto: PortfolioConfigurationDto): Observable<PortfolioGroupDto[]> {   
      return this.http.post(`${this.apiBase}portfolio/Update`, portfolioConfigurationDto).pipe(
      map(response => {
        return response as PortfolioGroupDto[];
      }));
  }

  public updateAutomatedPortfolio(changeStatus : any ): Observable<PortfolioGroupDto[]> {
      return this.http.post(`${this.apiBase}portfolio/Updatestatus`, changeStatus).pipe(
        map(response => {
          return response as PortfolioGroupDto[];
        }));
  }

  public cancelAllAutomatedPortfolio(): Observable<PortfolioGroupDto[]> {
    return this.http.post(`${this.apiBase}portfolio/Stopall`, null).pipe(
        map(response => {
          return response as PortfolioGroupDto[];
        }));
  }

  public getStandingdata(): Observable<StandingData> {
    return this.http.get(`${this.apiBase}standingdata/get`).pipe(
      map(response => {
        return response as StandingData;
      })
    )
  }

  public getPortfolioAuditCopy(): Observable<PortfolioConfigurationAuditCopyDto[]> {
    //const input = moment(tradeDate).format('YYYY-MM-DD');
    //const url = `${environment.backendUrl}/api/order/GetByTradedateAsync?tradeDate=${input}`;
    // const url = 'assets/testdata/orders.json';
    //return this.http.get(url)
    //  .map(response => {
    //    return response as OrderDto[];
    //  });
    return this.http.get(`${this.apiBase}portfolio/Getaudits`).pipe(
      map(response => {
        return response as PortfolioConfigurationAuditCopyDto[];
      }));
  }

  public archivePortfolio(id:any): Observable<any> {
    return this.http.delete(`${this.apiBase}portfolio/archive/${id}`);
  }
  public updateTimetoSwitchPortfolioConfiguration(portfolioConfigurationDto: PortfolioConfigurationDto): Observable<PortfolioGroupDto[]> {
   let timeToSwitch = portfolioConfigurationDto.timeToSwitch;
    return this.http.post(`${this.apiBase}portfolio/UpdateTimetoswitch`, { 'portfolioConfiguration': portfolioConfigurationDto, 'timeToSwitch': timeToSwitch }).pipe(
      map(response => {
        return response as PortfolioGroupDto[];
      }));
  }
  public stopAlgoTrader(status:any): Observable<any> {
    return this.http.post(`${this.apiBase}portfolio/Stopalgotrader`, status).pipe(
      map(response => {
        return response;
      }));
  }
  public checkAlgoTrader(): Observable<any> {
    return this.http.get(`${this.apiBase}portfolio/checkAlgoTrader`).pipe(
      map(response => {
        return response;
      }));
  }
}
