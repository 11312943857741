import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.scss']
})
export class AppErrorDialog {

    constructor( @Inject(MAT_DIALOG_DATA) public errorMessage: string) { }

    onNavigateToRequest() {


    }
}
