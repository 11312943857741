import { Injectable, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialog } from '../shared/dialog-warning/dialog-warning.component';
import { ConfirmationDialog } from '../shared/dialog-confirm/dialog-confirm.component';
import { Operations } from '../shared/enums';
import { PortfolioGroupDto, PortfolioConfigurationDto, PortfolioConfigurationAuditCopyDto } from './Portfolio-model';
import { DataService } from '../shared/data.service';
import { UserService } from '../shared/user.service';
import { OrderType, Strategy, SmartType } from '../shared/models/standing-data.model';
import { FormControl, AbstractControl, ValidatorFn, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-portfolio-configuration',
  templateUrl: './portfolio-configuration.component.html',
  styleUrls: ['./portfolio-configuration.component.scss']
})
@Injectable()
export class PortfolioConfigurationComponent implements OnInit {
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  isEnable: boolean = false;
  form = new FormGroup({
    details: new FormGroup({
      id: new FormControl(),
      likronPortfolioName: new FormControl(),
      strategy: new FormControl(),
      smartType: new FormControl(),
      orderType: new FormControl(),
      leadTimeStartH: new FormControl(''),
      leadTimeEndH: new FormControl(''),
      leadTimeStartQH: new FormControl('', [Validators.required]),
      leadTimeEndQH: new FormControl('', [Validators.required]),
      maxBuyPriceH: new FormControl('', [Validators.required]),
      maxBuyPriceQH: new FormControl('', [Validators.required]),
      maxSellPriceH: new FormControl('', [Validators.required]),
      maxSellPriceQH: new FormControl('', [Validators.required]),
      portfolioGroupId: new FormControl(),
      isActivated: new FormControl(),
      trader: new FormControl('')

    })
  });
  portfolioGroups: PortfolioGroupDto[];
  portfolioConfigurationDto: PortfolioConfigurationDto = {
    likronPortfolioName: '',
    strategy: 0,
    smartType: 0,
    orderType: 0,
    leadTimeStartH: 0,
    leadTimeEndH: 0,
    leadTimeStartQH: 0,
    leadTimeEndQH: 0,
    maxBuyPriceH: '',
    maxBuyPriceQH: '',
    maxSellPriceH: '',
    maxSellPriceQH: '',
    portfolioGroupId: 0,
    isActivated: false,
    inedit: false,
    trader: '',
    isArchive: false,
    timeToSwitch: new Date(),
    id:''
    
  };
  portfolioConfigurationDtoCopy: PortfolioConfigurationDto;
  selectedIndex: number = 0;
  selectedPortfoliotab: number = 0;
  editPermission = Operations.ReadWrite;
  readPermission = Operations.Read;
  canEdit = false;
  orderTypes: OrderType[];
  strategies: Strategy[];
  smartTypes: SmartType[];
  tabInfo: boolean = false;
  selDate: Date;
  //ELEMENT_DATA: PortfolioConfigurationDto[] = [];
  ELEMENT_DATA: PortfolioConfigurationAuditCopyDto[] = [];
  //dataSource: PortfolioConfigurationDto[];
  dataSource: PortfolioConfigurationAuditCopyDto[];
  portfolioDtls: PortfolioConfigurationAuditCopyDto[];
  i: number = 0;
  isEdit: boolean = false;
  private intervalId: any;
  currDate: string;
  color = 'accent';
  checked = false;
  selectedRow: number=-1;
  constructor(private dataService: DataService, private dialog: MatDialog, private user: UserService, private router: Router,
    fb: FormBuilder,
    private _formBuilder: FormBuilder, public datepipe: DatePipe) {
    this.myControl.setValidators(forbiddenNamesValidator(this.options));
  }
  


  ngOnInit(): void {

    this.dataService.checkAlgoTrader()
      .subscribe(response => {
        this.checked = response;
      });

   
    this.user.getADUsers()
      .subscribe(res => {
        console.log(" traders ", res[0]);
        this.options = res;
      });

    this.user.isAuthorized(this.editPermission)
      .subscribe(res => {
        this.canEdit = res;
      });

    this.user.isAuthorizedReadOrWrite(this.readPermission, this.editPermission)
      .subscribe(res => {
        if (!res) {
          this.router.navigate(['/accesserror']);
          return;
        }
      })

   
   
  }
  
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  } 
  
 

  
 
  changed() {
   // this.checked = !this.checked;
    console.log(this.checked);
    //let isSuccess;
    this.dataService.stopAlgoTrader(this.checked)
      .subscribe(response => {
        this.checked = response;
      });
    //return isSuccess;
  }
  
}

export function forbiddenNamesValidator(names: string[]): ValidatorFn {   
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    //const index = names.findIndex(name => {
    //  return (new RegExp('\^' + name + '\$')).test(control.value);
    //});
    return control.value === '' ? { 'forbiddenNames': { value: control.value } } : null;
    //return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
  };
}
