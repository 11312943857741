import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CdkTableModule } from '@angular/cdk/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select' ;
import { MatDialogModule } from '@angular/material/dialog' ;
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { PortfolioConfigurationComponent } from './portfolio-configuration/portfolio-configuration.component'
import { LoadingComponent } from './shared/loading/loading.component'
import { LoadingIndicatorService } from './shared/loading-indicator.service'
import { DialogConfirmService } from './shared/dialog-confirm/dialog-confirm.service';
import { ConfirmationDialog } from './shared/dialog-confirm/dialog-confirm.component';
import { WarningDialog } from './shared/dialog-warning/dialog-warning.component';
import { AppErrorDialog } from './shared/dialog-error/dialog-error.component';
import { ConfigService } from './shared/config.service';
import { DataService } from './shared/data.service';
import { Http } from './shared/http.service';
import { UserService } from './shared/user.service';
import {AuthHttpInterceptor, AuthService} from './shared/auth.service';
import {AuthenticationGuard} from "./shared/auth.guard";
import { CustomErrorComponent } from './shared/custom-error/custom-error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
//import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export function initConfig(configSrv: ConfigService) {
  return () => configSrv.initaliseConfig();
}

@NgModule({
  declarations: [
    AppComponent,    
    FooterComponent,
    MenuComponent,
    ToolbarComponent,
    PortfolioConfigurationComponent,
    LoadingComponent,
    ConfirmationDialog,
    WarningDialog,
    AppErrorDialog,
    CustomErrorComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    CdkTableModule,
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule,
    ApplicationInsightsModule.forRoot({     
      //instrumentationKey: "",
      instrumentationKeySetlater: true
    }),
    RouterModule.forRoot([
    { path: '', redirectTo: '/portfolio', pathMatch: 'full' },
    {
        path: 'portfolio',
        component: PortfolioConfigurationComponent,
        data: { title: 'Portfolio Configuration' },
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'accesserror',
        component: CustomErrorComponent,
        data: { title: 'Unauthorised user' }
    }
], { relativeLinkResolution: 'legacy' })
  ],
  providers: [
    LoadingIndicatorService,
    DialogConfirmService,
    ConfigService,
    {provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true},
    DataService,
    Http,
    UserService,
    AppInsightsService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    AuthService,
    AuthenticationGuard, DatePipe
 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
